<div class="pt-3">
  <div class="section-bg">
    <div class="container position-relative z-1">
      <div class="row py-4">
        <div class="col-12 py-4">
          <h1 class="fw-bold display-5 text-ping-gray text-center mb-5">Welcome to Backstage</h1>
          <app-search-bar/>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3">
    <app-dashboard-admin-panel/>
</div>
<div class="section-bg mt-3">
  <div class="z-1 position-relative">
    <div class="container pb-5">
      <div class="row">
        <div class="col-12">
          <app-dashboard-links-panel/>
          <app-dashboard-maintenance-info-panel/>
          <app-dashboard-verify-email-panel class="d-block"/>
          <app-dashboard-support-panel class="d-block"/>
          <app-dashboard-kb-panel class="d-block"/>
          <app-dashboard-university-panel class="d-block"/>
          <app-dashboard-marketplace-panel class="d-block"/>
        </div>
      </div>
    </div>
  </div>
</div>
